.new-message-container {
  display: block;
  width: 82%;
  padding: 50px 65px;
}

.new-message-container .message-setting-form {
  width: 60%;
}

.new-message-container .message-content-form {
  margin: 45px 0px;
}

.message-setting-form .edit-group .ant-form-item-label label {
  background-color: #f2f2f2;
  max-height: 26px;
}

.message-setting-form .ant-form-item-label > label {
  margin: 0px 10px 0px 0px;
}

.time-range-selector {
  width: 360px;
}
