.sidebar_container{
    width: 18%;
    height: 100vh;
    background-color: #606060;
}

.sidebar_container ul li{
    background-color: #595959;
    /*padding: 0 0 0 10px;*/
    font-size: 16px;
    color: #c1c1c1 !important;
    border-top: 2px #5f5f5f solid;
    border-bottom: 2px #5f5f5f solid;
}
.sidebar_overview{
    color: #c1c1c1 !important;
}

.ant-menu::before {
     display: none;
}
.ant-menu-inline .ant-menu-item:not(:last-child) {
     margin-bottom: 0 !important;
}
.ant-menu-inline .ant-menu-submenu{
    padding-bottom: 0 !important;
    width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-submenu-title {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.ant-menu-inline .ant-menu-item{
    margin-top: 0!important;
}
.sidebar_container ul{
    padding-inline-start: 0 ;
    margin-block-start: 3em;
    margin-block-end: 2em;
}

.anticon-home{
    margin-right: 8px;
    margin-left: 10px;
}

.ant-menu-item-selected{
    background-color: #357bae !important;
}

.ant-menu-item-selected span a{
    color: #c1c1c1!important;
}
.ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after{
    border-right: 3px solid #357bae !important;
}
.ant-menu-inline .ant-menu-item{
    margin-bottom: 0 !important;
}

.ant-menu-inline .ant-menu-item::after{
    border:none;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
     border-right: none;
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after{
    width: 15px;
    height: 2px;
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(193, 193, 193, 0.85)), to(rgba(193, 193, 193, 0.85))) !important;
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after{
    margin-right: 5px;
}
/**************************************/
/*****sub menu start from here*********/
/**************************************/
.ant-menu-sub li a{
    color: #c1c1c1 !important;
}
.ant-menu-sub li{
    width:100% !important;
}

.active{
    /*font-weight: 900!important;*/
    --antd-wave-shadow-color:#1890ff!important;;
}
