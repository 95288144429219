.report_artwork_container{
    display: flex;
    flex: 1;
}

.report_artwork_img{
    width: 60%;
    /*height: calc(100vh - 40px);*/
}

.report_artwork_img img{
    width: 100%;
    height: auto;
    border: 20px solid #cccccc;
}

.report_artwork_text{
    margin-left: 40px;
    width: 40%;
    font-size: 18px;
    text-align: left;

}
.report_artwork_text p span{
    font-weight: 600;
}


.line{
    height: 2px;
    width: calc(100% - 15px);
    background-color: #c1c1c1;
    margin: 60px 0;
}
