.container {
    display: block;
    width: 82%;
    padding: 20px;
  }
  
  .container > .form {
    display: flex;
    padding: 40px 40px 10px 40px;
  }
  
  .container .ant-form-item {
    margin-bottom: 10px;
  }
  
  
  .container .ant-form {
    width: 100%;
    margin-left: -30px;
    padding-right: 15%;
  }
  
  .container .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }
  
  .container .row-dragging td {
    padding: 16px;
    visibility: hidden;
  }
  
  .container .row-dragging .drag-visible {
    visibility: visible;
  }
  
  .container .table-title {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
  }
  
  .table-title button {
    margin: 10px;
  }
  
  .table-title .table-title-text {
    font-weight: 1000;
  }
  
  .container .ant-table-container {
    margin: 0px 22px;
    border: #8080802b solid 1px;
  }
  
  .container .ant-table-body {
    height: 550px;
  }
  
  