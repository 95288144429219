.history-message-container {
  display: block;
  width: 82%;
  margin: 20px;
}

.history-message-container .ant-table {
  border: 1px solid #8080802b;
}

.history-message-container .ant-table-thead {
  cursor: default;
}

.no-display {
  display: none;
}

.message-modal {
  right: -120px;
  top: 150px;
}

.message-modal .ant-modal-header {
  background-color: #f2f2f2;
  padding-top: 25px;
  padding-bottom: 5px;
}

.message-modal .ant-modal-body {
  text-align: left;
  padding-top: 25px;
  padding-bottom: 40px;
}
