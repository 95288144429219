.report_diary_container{
    width: 100%;
    padding: 0 8%;
}

.report_diary_title{
    width: 100%;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 2%;
}

.report-pool-diary-content img{
    width: 100%;
}

.ant-modal-footer {
     border-top: none;
     text-align: center;
}
