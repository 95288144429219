.overview_container {
    width: 100%;
    padding: 30px;
  }
  
  .overview_container > .overview_form {
    display: flex;
    width: 50%;
    float: left;
  }
  
  .overview_container .ant-form-item {
    margin-bottom: 0px;
  }
  
  