.challenge-container {
  display: block;
  width: 82%;
}

.challenge-container > .form {
  display: flex;
  padding: 40px 40px 10px 40px;
}

.challenge-container .ant-form-item {
  margin-bottom: 16px;
}

.challenge-container .ant-upload-picture-card-wrapper {
  width: auto;
}

.challenge-container .ant-form {
  width: 100%;
  margin-left: -30px;
  padding-right: 15%;
}

.challenge-container .row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.challenge-container .row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.challenge-container .row-dragging .drag-visible {
  visibility: visible;
}

.challenge-container .table-title {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}

.table-title button {
  margin: 10px;
}

.table-title .table-title-text {
  font-weight: 1000;
}

.challenge-container .ant-table-container {
  margin: 0px 22px;
  border: #8080802b solid 1px;
}

.challenge-container .ant-table-body {
  height: 280px;
}

