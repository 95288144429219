.history-processing-cancel{
    background-color: #7f7f7f;
    color: white;
    border: transparent;
    /*border-width: 2px;*/
    border-radius: 4px;
    padding:3px 8px;
}


.history-processing-status{
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    margin: 0 45%;
    border-bottom:2px solid #a8a8a8 ;
    border-right: 2px solid #a8a8a8;
    border-left: 2px solid #a8a8a8;
    background-color: #f2f2f2;

}
