.report_container{
    width: 100%;
    padding: 20px;
    background-color: #f2f2f2;
}


.report-pagination {
    padding: 16px 0 16px 73%;
    background-color: white;

}
.report-pagination .ant-pagination-options {
    margin-right: 16px;
}

.report_container .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container{
    border: 2px solid #a8a8a8;
}
.report_container .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-tbody > tr > td{
    border-bottom:1px solid #a8a8a8
}
.report_container .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content table .ant-table-thead > tr > th{
    border-bottom:1px solid #a8a8a8;
}
.report_container .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table table {
    background-color: #f8f8f8;
}

.report_container .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table table thead{
    background-color: #f8f8f8;
}

/*****************************/
/********pagination***********/
/*****************************/
.report-pagination .ant-pagination-item {
    background-color: #f2f2f2;
    border: transparent;
}
.report-pagination .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{
    background-color: #f2f2f2;
    border: transparent;
}
.report-pagination .ant-pagination-item-active{
    border-color: transparent;
    background-color: #7f7f7f;
}

.report-pagination .ant-pagination-item-active a{
    color: white;
}
.report-pagination .ant-pagination-item:focus a, .ant-pagination-item:hover a{
    color: white;
    background-color: #a8a8a8;
}

.report-pagination .ant-pagination-prev:focus .ant-pagination-item-link,.report-pagination .ant-pagination-next:focus .ant-pagination-item-link,.report-pagination .ant-pagination-prev:hover .ant-pagination-item-link,.report-pagination .ant-pagination-next:hover .ant-pagination-item-link{
    color: white;
    background-color: #a8a8a8;
}
