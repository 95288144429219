.user-man-container{
    width: 100%;
    padding: 20px 20px 20px 20px;
    background-color: #f2f2f2;
}

.user-man-top{
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    margin-bottom: 2%;
}

.user-man-search .ant-input-search{
    width: 400px;
}

.user-man-search{
    margin-right: 10%;
}

.user-man-checkboxes{
    width: 100%;
    display: flex;
    flex: 1;
    padding-top: 15px;
}

.user-man-checkboxes-normal ,.user-man-checkboxes-violation{
    width: 30%;
}

.user-man-checkboxes-normal p ,.user-man-checkboxes-violation p{
    display: inline-block;
    margin-right: 6%;
    font-size: 18px;
}

.user-man-checkboxes-normal label span .ant-checkbox-inner ,.user-man-checkboxes-violation label span .ant-checkbox-inner{
    width: 24px;
    height: 24px;
}

.user-man-search .ant-input-affix-wrapper{
    border-radius: 6px;
}

.user-man-search .ant-input-affix-wrapper .ant-input-suffix .ant-input-search-icon::before{
    content: none;
}

.user-man-search .ant-input-affix-wrapper span .anticon-search svg {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
}

.user-man-normal-btn{
    font-size: 18px;
    color:white;
    text-align: center;
    padding: 0 4px;
    background-color: #4B7902;
}

.user-man-ban-btn{
    font-size: 18px;
    background-color: #D9001B;
    color: white;
    text-align: center;
    padding: 0 4px;
}
/*****************************/
/********pagination***********/
/*****************************/
.user-man-pagination .ant-pagination-item {
    background-color: #f2f2f2;
    border: transparent;
}
.user-man-pagination .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{
    background-color: #f2f2f2;
    border: transparent;
}
.user-man-pagination .ant-pagination-item-active{
    border-color: transparent;
    background-color: #7f7f7f;
}

.user-man-pagination .ant-pagination-item-active a{
    color: white;
}
.user-man-pagination .ant-pagination-item:focus a, .ant-pagination-item:hover a{
    color: white;
    background-color: #a8a8a8;
}

.user-man-pagination .ant-pagination-prev:focus .ant-pagination-item-link,.report-pagination .ant-pagination-next:focus .ant-pagination-item-link,.report-pagination .ant-pagination-prev:hover .ant-pagination-item-link,.report-pagination .ant-pagination-next:hover .ant-pagination-item-link{
    color: white;
    background-color: #a8a8a8;
}
.user-man-pagination{
    padding: 16px 0 16px 57%;
    background-color: white;
}

.user-man-pagination .ant-pagination-options {
    margin-right: 16px;
}
