.report_detail_container{
    width: 82%;
    background-color: #f2f2f2;
    padding: 20px 40px;
    height: 100vh;
}
.report_detail_content_overflow{
    width: calc(100% - 60px);
    height: calc(100vh - 120px - 15%);
    overflow-y:auto;
    margin:  40px;
}
.report_detail_content{
    height: 85%;
    border: 2px solid #a8a8a8;
    background-color: white;
}

.report_detail_button{
    height: calc( 13% - 20px);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.report_detail_btn_bottom{
    margin-top: 3%;
}
.off_shelf{
    background-color: #7f7f7f;
    color: white;
    border: transparent;
    /*border-width: 2px;*/
    border-radius: 4px;
    padding:3px 8px;
}

.close-btn{
    width: auto;
}

.close-btn span{
    margin-left: 97%;
    width: 20px;
    height: auto;
}

.close-btn span svg {
    width: 2em;
    height: 2em;
}

.report_modal_btn{
    margin: 0 40px;
}

.report_modal_confirm{
   background-color:  #f2f2f2;
    border: none;
}
.ant-modal-body{
    text-align: center;
    left: -20px;
    padding: 24px 24px 14px 24px!important;
    max-width: calc(100vw) !important;
}
.report_detail_modal_container .ant-modal-content .ant-modal-close{
    padding-top: 50px;
    text-align: center;
    display: none;

}
.report_detail_modal_container .ant-modal-content{
    width: 70%;
}
.report_modal_cancel{
    background-color:#7f7f7f;
    color: white;
    border: none;
}
.ant-modal-root .report-modal-wrap .ant-modal{
    padding-bottom: 0!important;
    height: 100vh!important;
}
