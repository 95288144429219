.banner_container {
  display: block;
  width: 82%;
}

.banner_container > .banner_form {
  display: flex;
  padding: 40px;
}

.banner_container .ant-form-item {
  margin-bottom: 16px;
}

.banner_container .ant-upload-picture-card-wrapper {
  width: auto;
}

.banner_container .row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.banner_container .row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.banner_container .row-dragging .drag-visible {
  visibility: visible;
}

.banner_container .banner_table_title {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
}

.upload_btn {
  width: 200px;
  height: 200px;
  margin-right: 20px;
  border: 2px solid #ccc;
}
